<template>
  <v-container fluid class="pa-0">
    <v-card flat tile max-width="1200" class="mx-auto d-flex flex-column">
      <v-data-table
        :headers="headers"
        :items="rechargeRecord"
        hide-default-footer
        class="mx-4"
        :page.sync="page"
        @page-count="pageCount = $event"
        :items-per-page="8"
        style="border:2px solid rgba(39,135,255,0.3);"
      ></v-data-table>
      <div class="text-center pt-2">
        <v-pagination
          v-model="page"
          :length="pageCount"
          max-width="500"
          outlined
          :total-visible="10"
        ></v-pagination>
      </div>
    </v-card>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    page: 1,
    pageCount: 0,
    headers: [
      {
        text: "充值单号",
        align: "start",
        sortable: false,
        value: "number",
        class: "head-style title"
      },
      { text: "操作时间", value: "time", class: "head-style title" },
      { text: "充值金额", value: "money", class: "head-style title" },
      { text: "充值前余额", value: "balance", class: "head-style title" },
      { text: "充值方式", value: "channel", class: "head-style title" },
      { text: "充值状态", value: "status", class: "head-style title" }, //状态 1：成功；2：失败；3：未支付
      { text: "操作人", value: "username", class: "head-style title" },
      { text: "备注", value: "remark", class: "head-style title" }
    ],
    // 充值记录
    rechargeRecord: []
  }),
  created() {
    this.info();
  },
  methods: {
    info() {
      //获取用户基础信息
      const infonList = JSON.parse(localStorage.getItem("info"));
      // 获取充值记录
      this.$server.rechargeRecord().then(data => {
        this.rechargeRecord = data.data;
        for (const i in this.rechargeRecord) {
          this.rechargeRecord[i].time = this.$moment
            .unix(this.rechargeRecord[i].time)
            .format("YYYY-MM-DD HH:mm:ss");
          switch (parseInt(this.rechargeRecord[i].status)) {
            case 1:
              this.rechargeRecord[i].status = "成功";
              break;
            case 2:
              this.rechargeRecord[i].status = "失败";
              break;
            case 3:
              this.rechargeRecord[i].status = "未支付";
              break;
          }
        }
      });
    }
  }
};
</script>

<style>
</style>